import React from 'react'
import img from '../../images/liposukcja-kawitacyjna-cialo.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const LiposukcjaKawitacyjna = () => {
    return (
        <>
            <Layout>
                <Seo
                    title='Liposukcja Kawitacyjna | Modelowanie Sylwetki'
                    description='Liposukcja kawitacyjna jest to bezinwazyjny zabieg usuwania nadmiernej
          lub niechcianej tkanki tłuszczowej przy pomocy ultradźwięków.'
                    url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-cialo/liposukcja-kawitacyjna'
                />

                <div className='page-container'>
                    <div className='page'>
                        <div className='content-1'>
                            <h1>Liposukcja Kawitacyjna</h1>
                            <div className='section'>
                                <div className='image-container'>
                                    <img
                                        src={img}
                                        width='1000px'
                                        height='667px'
                                        alt='Liposukcja Kawitacyjna zdjęcie'
                                    ></img>
                                </div>
                                <div className='text'>
                                    <p>
                                        Liposukcja kawitacyjna jest to bezinwazyjny zabieg
                                        usuwania nadmiernej lub niechcianej tkanki
                                        tłuszczowej przy pomocy ultradźwięków. Podczas
                                        zabiegu liposukcji kawitacyjnej emitowane są przez
                                        głowicę urządzenia ultradźwięki, które docierają
                                        do komórek tłuszczowych obecnych w podskórnej
                                        tkance tłuszczowej. Ultradźwięki o odpowiednio
                                        dobranej częstotliwości i mocy wywołują zjawisko
                                        kawitacji w tkankach, powodując uszkodzenie błony
                                        komórkowej komórek tłuszczowych i wydostanie się
                                        zgromadzonego w nich tłuszczu. Uwolnione w ten
                                        sposób produkty tłuszczowe są transportowane przez
                                        układ limfatyczny i naczyniowy do wątroby, gdzie
                                        są metabolizowane.
                                    </p>
                                    <p>
                                        Za pomocą liposukcji kawitacyjnej możemy usunąć
                                        nadmiar tkanki tłuszczowej z wybranych obszarów.
                                        Skóra ulega zagęszczeniu i zmniejsza się
                                        widoczność cellulitu. Stosowany jest wszędzie tam,
                                        gdzie może znajdować się problematyczna i trudna
                                        do zwalczenia tkanka tłuszczowa: brzuch, uda,
                                        pośladki, ramiona. Najlepsze efekty osiąga się
                                        stosując serię zabiegów wykonywanych 2 razy w
                                        tygodniu. Pojedynczy zabieg trwa 30 minut.
                                    </p>
                                    <div className='cennik'>
                                        <span>CENNIK</span>
                                        <h2>LIPOSUKCJA KAWITACYJNA - WYBRANA PARTIA</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    LIPOSUKCJA KAWITACYJNA - POJEDYNCZY
                                                    ZABIEG
                                                </p>
                                                <span className='prices'>
                                                    30 MIN | 247 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    LIPOSUKCJA KAWITACYJNA -{' '}
                                                    <span>PAKIET 8 </span>
                                                    ZABIEGÓW
                                                </p>
                                                <span className='prices'>
                                                    30 MIN | 1357 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    LIPOSUKCJA KAWITACYJNA -{' '}
                                                    <span>PAKIET 12 </span>
                                                    ZABIEGÓW
                                                </p>
                                                <span className='prices'>
                                                    30 MIN | 1787 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    LIPOSUKCJA KAWITACYJNA - POJEDYNCZY
                                                    ZABIEG
                                                </p>
                                                <span className='prices'>
                                                    40 MIN | 297 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    LIPOSUKCJA KAWITACYJNA -{' '}
                                                    <span>PAKIET 8 </span>
                                                    ZABIEGÓW
                                                </p>
                                                <span className='prices'>
                                                    40 MIN | 1497 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    LIPOSUKCJA KAWITACYJNA -{' '}
                                                    <span>PAKIET 12 </span>
                                                    ZABIEGÓW
                                                </p>
                                                <span className='prices'>
                                                    40 MIN | 1887 zł
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Fade left>
                            <Link to='/kontakt'>
                                <button>Umów Wizytę</button>
                            </Link>
                        </Fade>
                    </div>
                </div>
            </Layout>
            <div className='copyright-notice'>
                <a href='https://www.freepik.com/free-photo/sexy-body-mixed-race-woman-standing-profile-keeps-hand-buttocks-dressed-underwear-has-perfect-shining-skin-slim-figure_15224621.htm'>
                    Image by wayhomestudio
                </a>{' '}
                <div>on Freepik</div>
            </div>
        </>
    )
}

export default LiposukcjaKawitacyjna
